<template>
  <div class="home dark">

    <a class="btn-linkedin" target="_blank" href="https://www.linkedin.com/in/kmedini/"><span>Retrouvez moi sur </span><img :src="'./icons/linkedin.png'"></a>

    <div class="container">
      <h1 class="text-gray-500 text-white text-5xl sm:text-6xl md:text-6xl lg:text-8xl tracking-tight font-extrabold">
        Hey <span class="inline-block origin-70 hover:animate-wave">👋</span>
        moi c'est Karim, <br class="hidden sm:block">je suis <div class="inline-flex px-3 lg:px-5 py-2 md:pb-4 bg-primary-500 bg-opacity-15 backdrop-filter backdrop-blur-sm filter saturate-200 text-primary-200 rounded-2xl default-transition default-focus mt-4" target="_blank" rel="noreferrer noopener">{{ showPosition() }}</div></h1>

      <p class="text-base text-base-justify text-gray-300 sm:text-lg md:text-xl md:max-w-3xl">J'ai un parcours atypique, Ex-militaire en tant qu'Analyste programmeur, j'ai depuis 2014 rejoins la vie civil ou j'ai été développeur, lead, CTO (@Doinsport) et vécu plusieurs expérience entreprenarial. Mon expérience m'a permis de valider une expertise de développeur FullStack, de lead technique et de manager.</p>

      <h2 class="text-white">Mes techno favorites</h2>

      <div class="row-skills">
        <div class="skill" v-for="item in skills" v-bind:key="item.id">
          <img :src="item.img" width="100px" height="100px">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  },
  created() {
    this.changePosition();
  },
  methods: {
    changePosition() {
      this.timeOut = setInterval(() => {
        if (this.position >= (this.positions.length - 1)) {
          this.position = 0;
        } else {
          this.position++;
        }
        clearInterval(this.timeOut);
        this.changePosition();
      }, 2000);
    },
    showPosition() {
      return this.positions[this.position];
    }
  },
  data() {
    return {
      timeOut: null,
      position: 0,
      positions: ['developper', 'CTO', 'lead tech', 'advisor'],
      skills: [
        {id: 0, name: 'Symfony', img: './icons/symfony.png'},
        {id: 1, name: 'Node JS', img: './icons/nodejs.jpeg'},
        {id: 2, name: 'Ionic', img: './icons/ionic.png'},
        {id: 3, name: 'Angular', img: './icons/angular.svg'},
        {id: 4, name: 'Vue Js', img: './icons/vuejs.png'},
        {id: 5, name: 'React', img: './icons/react.png'},
      ],
    }
  }
}
</script>

<style scoped lang="scss">
.home {
  overflow-y: scroll;
  padding: 30px;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  .btn-linkedin {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    font-size: 17px;
    font-weight: 800;
    img {
      min-width: 40px;
      max-width: 40px;
      min-height: 40px;
      max-height: 40px;
      object-fit: cover;
      margin-left: 10px;
    }
  }
  .container {
    margin: auto;
    width: 100%;
    max-width: 900px;
  }
  .text-white {
    color: white;
  }
  .row-skills {
    width: 100%;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    .skill {
      margin: auto;
      padding: 10px;

      img {
        width: auto;
        height: 60px;
        object-fit: cover;
      }
    }
  }
}
h1 {
  font-size: 3rem;
}
.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
}

.text-primary-200 {
  --tw-text-opacity: 1;
  color: rgba(30,144,255,var(--tw-text-opacity));
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.mt-4 {
  margin-top: 1rem;
}
.inline-flex {
  display: inline-flex;
}
.rounded-2xl {
  border-radius: 1rem;
}
.bg-primary-500 {
  --tw-bg-opacity: 0.15;
  background-color: rgba(0,114,255,var(--tw-bg-opacity));
}
.default-transition {
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform,filter,-webkit-backdrop-filter;
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .15s;
  transition-duration: .3s;
}

.dark {
  --tw-bg-opacity: 1;
  background-color: rgba(12,14,16,var(--tw-bg-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(167,169,172,var(--tw-text-opacity));
}
.text-base-justify {
  margin: 20px auto 10px;
  text-align: justify;
  max-width: 700px;
  width: 100%;
}
</style>
